import {
  React,
  PropTypes,
  Box,
  TextField,
  Password,
  Form,
  Button,
  LoadingDots,
} from 'library'
import s from './styles.module.scss'

const LoginForm = ({ form }) => {
  const email = form.field('email')
  const password = form.field('password')
  return (
    <>
      <Box className={s.fields}>
        <TextField
          type="email"
          required
          label="Email"
          placeholder="Enter your email"
          {...email}
        />
        <Password {...password} />
      </Box>
      <Button submit className={s.button}>
        {email.submitted && !email.error && !password.error ? (
          <LoadingDots />
        ) : (
          <>Login &rarr;</>
        )}
      </Button>
    </>
  )
}

LoginForm.propTypes = {
  form: PropTypes.object,
}

export default Form(LoginForm)
