import { useMutation } from '@apollo/client'
import gql from 'graphql-tag.macro'
import mapErrors from './mapErrors'
import useEvent from 'elements/analytics/useEvent'
const mutation = gql`
  mutation loginEmail($body: JSON!) {
    loginEmail(input: $body)
      @rest(type: "login-email", path: "/login-email", method: "POST") {
      success
      message
    }
  }
`

const useLogin = ({ onCompleted, onError } = {}) => {
  const event = useEvent()

  const [login, { data, error }] = useMutation(mutation, {
    onCompleted: data => {
      if (onCompleted) onCompleted()
      event('login with email', { label: 'AUTH' })
    },
    onError: error => {
      if (onError) onError(mapErrors(error))
    },
    context: { route: 'public' },
  })

  return {
    call: variables => login({ variables: { body: variables } }),
    error: mapErrors(error),
    data: data && data.loginEmail,
  }
}

export default useLogin
