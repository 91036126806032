import { React, Column, TextLink, FormMessage, H2 } from 'library'
import s from './styles.module.scss'
import Site from 'components/Site'
import WithPw from './WithPw'
import WithEmail from './WithEmail'
import MtnSection from 'components/MtnSection'
import LogoHeader from 'components/LogoHeader'
import IsAuth from 'components/IsAuth'

import useLogin from 'api/auth/useLogin'
import useLoginEmail from 'api/auth/useLoginEmail'
import mountain3 from 'pages/Home/img/mountain3.svg'

const Login = ({ location }) => {
  const [withEmail, setWithEmail] = React.useState(!location.state?.password)

  return (
    <Site
      title="Login to Fire Tower"
      className={s.page}
      banner={location.state?.loggedOut && "You're now logged out."}>
      <MtnSection color="light" img={mountain3} small>
        {!location.state?.loggedOut && <IsAuth login />}
        <Column size="small" box>
          <LogoHeader />
          {withEmail ? <Email /> : <Pw />}
        </Column>
      </MtnSection>
      <MtnSection color="lightBlue" small>
        {withEmail ? (
          <TextLink onClick={() => setWithEmail(false)}>
            Login with Password
          </TextLink>
        ) : (
          <>
            <TextLink to="/forgot-password">Forgot Password?</TextLink>
            <TextLink onClick={() => setWithEmail(true)}>
              Login with Email
            </TextLink>
          </>
        )}

        <TextLink to="/signup">Sign Up for Fire Tower</TextLink>
      </MtnSection>
    </Site>
  )
}

const Pw = () => {
  const { call, error } = useLogin()

  return <WithPw skipUnsavedAlert onSubmit={call} error={error} />
}

const Email = () => {
  const { call, error, data } = useLoginEmail()

  return (
    <>
      <FormMessage>{data?.message}</FormMessage>
      <H2>Enter your email and we will send you a login link.</H2>
      <WithEmail
        skipUnsavedAlert
        onSubmit={call}
        error={error}
        success={data?.success}
      />
    </>
  )
}

Login.propTypes = {}

export default Login
