import { useMutation } from '@apollo/client'
import gql from 'graphql-tag.macro'
import mapErrors from './mapErrors'
import useEvent from 'elements/analytics/useEvent'
import usePush from 'elements/routes/usePush'
const mutation = gql`
  mutation login($body: JSON!) {
    login(input: $body) @rest(type: "login", path: "/login", method: "POST") {
      success
    }
  }
`

const useLogin = ({ onCompleted, onError } = {}) => {
  const event = useEvent()
  const push = usePush()

  const [login, { error }] = useMutation(mutation, {
    onCompleted: () => {
      if (onCompleted) onCompleted()
      push('/')
      event('login with password', { label: 'AUTH' })
    },
    onError: error => {
      if (onError) onError(mapErrors(error))
    },
    context: { route: 'public' },
  })

  return {
    call: variables => login({ variables: { body: variables } }),
    error: mapErrors(error),
  }
}

export default useLogin
